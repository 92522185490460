import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useMosaiquePage } from "../services/firestore";
import GoogleMapReact from "google-map-react";
import markerImage from "../images/mapCursor.png";
import { Highlight } from '../components/Highlight'

const Groups = ({ groups }) => {
  return (
    <Highlight isPrimary={true}>
      <ul className="flex flex-wrap text-left">
        {groups.map((data) => (
          <li key={data.title} className="lg:w-1/3">
            <dl className="p-4 lg:p-10">
              <dt className="text-lg font-bold">{data.title}</dt>
              <dt className="text-base">{data.name}</dt>
            </dl>
          </li>
        ))}
      </ul>
    </Highlight>
  );
};

function createMapOptions(maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControlOptions: {
      // position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true,
  };
}

const MapCreator = ({groups}) => {

  const defaultProps = {
    center: {
      lat: 47.89690020843811,
      lng: 1.9108849639518422,
    },
    zoom: 11,
  };
  const renderMarkers = (map, maps) => {
    let markers=[]
    groups.forEach(function (group){
      if (group.lat!==undefined && group.lng!==undefined){
        const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h1 id="firstHeading" class="firstHeading">'+group.title+'</strong></h1>' +
          '<div id="bodyContent">' +
          "</p>" +
          "</div>" +
          "</div>";
        const infowindow = new maps.InfoWindow({
          content: contentString,
        });

        var image = {
          url: markerImage,
          size: new maps.Size(51, 75),
          origin: new maps.Point(0, -25),
          anchor: new maps.Point(25, 50)
        };

        let marker = new maps.Marker({
          position: { lat: group.lat, lng: group.lng },
          map,
          title: groups.title,
          animation: maps.Animation.DROP,
          icon: image
        });
        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });
        markers.push(marker);
      }
      
    });
    return markers;
    
  };

  return (
    <div className="w-full h-96	">
      <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAf1dRqScFYwrNm0zwXDQJHhDFXn1SCpxQ" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          options={createMapOptions}
      ></GoogleMapReact>
    </div>
  );
};


const MosaiquePage = ({ data }) => {
  const page = useMosaiquePage(data.allMosaiquePages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Mosaîque"}>
      {page && (
        <Highlight
          title={page.title}
          subtitle={page.subtitle}
          text={page.highlight}
        />
      )}
      {page && <MapCreator groups={page.groups} />}
      {page && <Groups groups={page.groups}></Groups>}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMosaiquePages {
      nodes {
        title
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        groups {
          title
          name
          lat
          lng
        }
      }
    }
  }
`;

export default MosaiquePage;
